<template>
  <div class="forgotpass-custom">
    <header class="modal-header py25 px65 fs28 serif weight-700 cl-white bg-cl-main">
      {{ $t('Reset password') }}
      <i
        slot="close"
        class="modal-close icon-zamknij cl-bg-tertiary"
        @click="close"
      />
    </header>

    <div class="modal-content  bg-cl-main-smoke pt30 pb60 px65 cl-black">
      <template v-if="!passwordSent">
        <form @submit.prevent="sendEmail" novalidate>
          <div class="mb20">
            <p class="forgot-text">
              {{ $t('Enter your email to receive instructions on how to reset your password.') }}
            </p>
            <base-input
              type="email"
              name="email"
              v-model="email"
              focus
              :placeholder="$t('E-mail address *')"
              :validations="[
                {
                  condition: !$v.email.required && $v.email.$error,
                  text: $t('Field is required.')
                },
                {
                  condition: !$v.email.email && $v.email.$error,
                  text: $t('Please provide valid e-mail address.')
                }
              ]"
            />
          </div>
          <button-full class="mb35 w-100 fs16 reset-btn" type="submit">
            {{ $t('Reset password') }}
          </button-full>
          <div class="center-xs">
            {{ $t('or') }}
            <a href="#" class="loginLink" @click.prevent="switchElem">
              {{ $t('return to log in') }}
            </a>
          </div>
        </form>
      </template>
      <template v-if="passwordSent">
        <form class="py20 center-xs">
          <p class="py30 mb80">
            {{ $t("We've sent password reset instructions to your email. Check your inbox and follow the link.") }}
          </p>
          <button-full class="mb35 button" type="link" @click.native="switchElem">
            {{ $t('Back to login') }}
          </button-full>
        </form>
      </template>
    </div>
  </div>
</template>

<script>

import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import BaseInput from '../Form/BaseInput.vue'
import { required, email } from 'vuelidate/lib/validators'
import { Logger } from '@vue-storefront/core/lib/logger'
import i18n from '@vue-storefront/i18n'

export default {
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    close () {
      this.$bus.$emit('modal-hide', 'modal-signup')
    },
    sendEmail () {
      // todo: send email with reset password instructions

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t('Please fix the validation errors'),
          action1: { label: i18n.t('OK') }
        })
        return
      }

      this.$bus.$emit('notification-progress-start', i18n.t('Resetting the password ... '))
      this.$store.dispatch('user/resetPassword', { email: this.email }).then((response) => {
        this.$bus.$emit('notification-progress-stop')
        if (response.code === 200) {
          this.passwordSent = true
        } else {
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: i18n.t(response.result) || i18n.t('Error while sending reset password e-mail'),
            action1: { label: i18n.t('OK'), action: 'close' }
          })
        }
      }).catch((err) => {
        Logger.error(err)()
        this.$bus.$emit('notification-progress-stop')
      })
    },
    switchElem () {
      this.$store.commit('ui/setAuthElem', 'login')
    }
  },
  name: 'ForgotPass',
  data () {
    return {
      email: '',
      passwordSent: false
    }
  },
  components: {
    ButtonFull,
    BaseInput
  }
}
</script>

<style lang="scss">
  .forgotpass-custom {
    input {
      border-color: white;
    }
  }
</style>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$main: color(main);
$light-gray: color(light-gray);

  .fs28 {
    font-size: 28px;
  }
  .modal-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .reset-btn {
    @media (max-width: 767px) {
      padding: 10px 40px;
    }
  }
  .forgot-text {
    margin: 0 0 45px 0;
    font-size: 16px;
    @media (max-width: 767px) {
      color: $light-gray;
    }
  }
  .modal-close{
    cursor: pointer;
  }
  .loginLink {
    color: $main;
    &:hover {
      text-decoration: underline;
    }
  }
  .modal-content {
    @media (max-width: 400px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .button {
    margin-left: auto;
    margin-right: auto;
  }
</style>
